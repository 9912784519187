export const skills = [
  "Semantic & accesible HTML",
  "CSS Grid",
  "CSS BEM convetion",
  "CSS animations",
  "CSS Flexbox",
  "Responsive Web Design",
  "JavaScript - ES6+",
  "React",
  "React Hooks",
  "React Router",
  "Redux - Saga",
  "Redux - Toolkit",
  "Styled - Components",
  "Markdown",
  "NPM",
  "Git",
  "Github",
  "Working with API (fetch, axios)",
  "Immutability",
  "Promises, Async/Await",
  "Error handling",
  "PHP",
  "SQL",
  "Scrum",
  "Trello",
  "Teamwork",
];

export const nextSkills = ["TypeScript", "Node.js", "Symfony"];
